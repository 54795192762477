<template>
  <div class="bt-anggota pa-5">
    <Header title="Wakaf Kumpulan" titleIcon="mdi-account-group" />
    <div class="py-3 bth-rembug-box">
      <v-select solo label="Hari" class="mb-4" hide-details :items="opt_hari" item-text="day_name" item-value="day_code"
        v-model="selectedItem" @change=getRembug(selectedItem) />
      <v-menu ref="menu" v-model="dateShow" :close-on-content-click="false" :return-value.sync="transaction_date"
        transition="scale-transition" offset-y min-width="auto" class="white">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field solo v-model="transaction_date" label="Tanggal" readonly v-bind="attrs" v-on="on" />
        </template>
        <v-date-picker v-model="transaction_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateShow = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="
              $refs.menu.save(transaction_date);
            ">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-card v-for="(rbg, rbgIndex) in rembug" :key="rbgIndex" class="pa-5 mb-3">
        <h6 class="text-h6 mb-1 font-weight-bold text-center">
          Kp. {{ rbg.cm_name }}
        </h6>
        <h1 class="text-h4 font-weight-black purple--text text--lighten-1 d-flex justify-space-between align-center">
          <span class="text-body-2 font-weight-black grey--text">Jumlah Anggota</span>
          {{ rbg.jumlah }}
        </h1>
        <p class="text-caption mb-4">
          {{ rbg.nama_desa }}
        </p>
        <v-row>
          <v-col cols="12">
            <router-link
              :to="(transaction_date !== null) ? `/others/wakaf/kelompok/${rbg.cm_code}/${rbg.branch_id}/${transaction_date}` : ''">
              <v-btn block class="purple lighten-1 white--text rounded-lg" type="button"
                :disabled="(transaction_date==null)">
                Lihat Kumpulan
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Toast from "@/components/Toast";
import Header from "@/components/Header";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
export default {
  name: "WakafKumpulan",
  components: {
    Toast,
    Header,
  },
  data() {
    return {
      overlay: false,
      rembug: [],
      selectedItem: null,
      transaction_date: null,
      dateShow: false,
      opt_hari: [
        {
          day_name: 'Senin',
          day_code: 1
        },
        {
          day_name: 'Selasa',
          day_code: 2
        },
        {
          day_name: 'Rabu',
          day_code: 3
        },
        {
          day_name: 'Kamis',
          day_code: 4
        }
      ],
      alert: {
        show: false,
        msg: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async getRembug(day = '') {
      this.overlay = true;
      let hari_transaksi = new Date().getDay();

      if (day == '') {
        hari_transaksi = this.user.hari_transaksi;
      } else {
        hari_transaksi = day;
      }

      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("hari_transaksi", hari_transaksi);
      try {
        let req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            this.rembug = req.data.data;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    getDate() {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      return `${year}-${month}-${day}`;
    },
  },
  mounted() {
    //this.getRembug();
  },
};
</script>
